import React, { useState } from 'react';
import Button from 'components/Button';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import './save-exit.scss';

export default ({ title, content, link, buttonName }) => {
  const [modal, toggleModal] = useState(false);

  return (
    <div className="saveExit-modal">
      <Button
        type="app-transparent-blue-button"
        handleClick={() => toggleModal(!modal)}
      >
        {buttonName}
      </Button>

      <Modal
        isOpen={modal}
        toggle={() => toggleModal(!modal)}
        backdrop={'static'}
        centered={true}
        className="save-exit-modal"
      >
        <div className="modal-content">
          <ModalHeader toggle={() => toggleModal(!modal)}></ModalHeader>
          <ModalBody>
            <div>
              <h3 className="modal-title">{title}</h3>
            </div>
            <div className="modal-content">
              <p>{content}</p>
            </div>
          </ModalBody>
          <ModalFooter className="modal-footer">
            <Button
              type="app-primary-button"
              handleClick={() => toggleModal(!modal)}
            >
              Stay
            </Button>
            <Button
              type="app-transparent-no-border-button"
              linkAsButton={true}
              path={link}
            >
              Leave
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    </div>
  );
};
