import React from 'react';
import Button from 'components/Button';
import SaveExit from 'components/SaveExit';
import SubmitModal from 'components/SubmitModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons';
import { CONSIGNMENT_PATH } from 'src/utils/pathsConstants';

const Footer = ({
  step,
  handleBack,
  handleNext,
  isValid,
  dirty,
  submit,
  showSubmitModal,
  closeSubmitModal,
  isSubmitting,
  touched,
}) => {
  let title = 'Are you sure you want to leave the cosign inquiry?';
  let content =
    'By leaving this page none of you consignment information will be saved or submitted.';
  let link = CONSIGNMENT_PATH;

  let submitTitle = 'SUBMITTED';
  let submitContent =
    'Thank you for your submission. A Gooding representative will be reaching out to connect.';

  return (
    <footer>
      {step === 1 ? (
        <Button type="app-transparent-blue-button" handleClick={handleBack}>
          <FontAwesomeIcon icon={faLongArrowAltLeft} />
          Back
        </Button>
      ) : (
        step === 1 && (
          <div className="flex-wrapper">
            <FontAwesomeIcon icon={faLongArrowAltLeft} />
            <SaveExit
              title={title}
              content={content}
              link={link}
              buttonName="Back"
            />
          </div>
        )
      )}
      <div className="flex-wrapper w-100 justify-content-end">
        <SaveExit
          title={title}
          content={content}
          link={link}
          buttonName="Save & Exit"
        />
        {step === 1 ? (
          <SubmitModal
            submitTitle={submitTitle}
            submitContent={submitContent}
            isValid={isValid}
            submit={submit}
            isSubmitting={isSubmitting}
            touched={touched}
            // showSubmitModal={showSubmitModal}
            // closeSubmitModal={closeSubmitModal}
          />
        ) : (
          <Button
            type={
              isValid ? 'app-primary-button' : ' app-primary-button inactive'
            }
            handleClick={handleNext}
          >
            Next
          </Button>
        )}
      </div>
    </footer>
  );
};

export default Footer;
